import request from '@/utils/request';

export async function queryTag(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/configure_manage/query_tag_all_list', params);
}

export async function removeTag(params: Record<string, any>) {
  return request.post('/configure_manage/delete_tag', params);
}
export async function recoverTag(params: Record<string, any>) {
  return request.post('/configure_manage/recover_tag', params);
}

export async function addTag(params: Record<string, any>) {
  return request.post('/configure_manage/create_tag', params);
}

export async function updateTag(params: Record<string, any>) {
  return request.post('/configure_manage/update_tag', params);
}

interface RuleItem {
  key: string | number;
  callNo: number;
  avatar: string;
  desc: string;
  disabled: false;
  href: string;
  name: string;
  owner: string;
  progress: number;
  status: number;
  updatedAt: string;
  createdAt: string;
}

interface RoleResponse {
  current: number;
  data: RuleItem[];
  pageSize: string;
  success: boolean;
  total: number;
}
